










import Vue from "vue";
export default Vue.extend({
  props: {
    dark: Boolean,
  },
  computed: {
  
    img: function () {
      if (this.dark) {
        return require("@/assets/img/forward-arrow-dark.png");
      } else return require("@/assets/img/forward-arrow.png");
    },
  },
});
